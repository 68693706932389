import React, { Component } from 'react';

import NavControls from './NavControls';
import Debug from './Debug';
import MainTemplate from './MainTemplate';

import * as d3 from "d3";

class MainFrame extends Component {

    constructor(props) {
        super(props);
        this.element = React.createRef();
        this.sectionsTemplate = React.createRef();
        this.state = {
            debug: false,
            scroll: 0,
            scrolling: false,
            locations: [0],
            section: 0,
            w: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
            h: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
            ratio: "",
        };
    }

    updateNavControls = (props) => {
        let classes = this.props.classes;
        let section = this.state.section;
        d3.select('.' + classes.navControls).style('opacity', '1');
        //
        d3.select('.' + classes.triangleDown).style('opacity', '1');
        d3.select('.' + classes.triangleUp).style('opacity', '1');
        if (section === 0) {
            d3.select('.' + classes.triangleUp).style('opacity', '0');
        } else if (section === 3) {
            d3.select('.' + classes.triangleDown).style('opacity', '0');
        }
    }

    scrollToSection = (section) => {
        const sectionsTemplate = this.sectionsTemplate;
        if (sectionsTemplate.current) {
            const element = document.getElementById('MainTemplate')
            let scroll = this.state.scroll;
            let target = this.state.locations[section];
            let offset = target - scroll;
            let classes = this.props.classes;

            d3.selectAll('#section1, #section2, #section3, #section4').style('scroll-snap-align', 'none');
            d3.select('.' + classes.navControls).style('opacity', '0');

            d3.transition()
                .duration(2000)
                .tween("scroll", scrollTween(offset))
                .on('end', function() {
                    d3.selectAll('#section1, #section2, #section3, #section4').style('scroll-snap-align', 'start');
                })


            function scrollTween(offset) {
                return function() {
                    var i = d3.interpolateNumber(scroll || document.documentElement.scrollTop, target);
                    return function(t) {
                        element.scrollTo(0, i(t));
                    };
                };
            }
        }
    }

    updateSection = () => {
        let section = this.state.section;
        let currentScroll = this.state.scroll;
        let locations = this.state.locations;
        const findClosest = function(arr, num) {
            return arr.reduce((prev, current) => Math.abs(current - num) < Math.abs(prev - num) ? current : prev);
        }

        const closest = findClosest(locations, currentScroll);

        for (let index = 0; index < this.state.locations.length; index++) {
            const element = this.state.locations[index];
            if (element === closest) {
                section = index;
            }
        }
        this.setState({
            section: section,
        }, () => {
            if (this.state.debug === true) {
                // d3.select('#debug').select(".fourth-line").html("section: " + section + " " + this.state.locations);
                d3.select('#debug').select(".fourth-line").html("section: " + section);
            }
            this.updateNavControls();   
        });
    }

    updateScroll = (scroll) => {
        this.setState({
            scroll: scroll,
        }, () => {
            this.updateSection();
            if (this.state.debug === true) {
                d3.select('#debug').select(".third-line").html("scroll: " + scroll);
            }
        });
    }

    updateLocations = () => {
        let locations = [0];
        if (this.sectionsTemplate.current) {
            locations = this.sectionsTemplate.current.getSectionLocations();
        }
        this.setState({
            locations: locations,
        });
    }

    updateSizeAndRatio = (w, h) => {
        const format = d3.format(".2f");
        let ratio;
        let value = format(w / h);
        if (w < h / 2) {
            ratio = value + ":2";
        } else if (h < w / 2) {
            ratio = value + ":1";
        } else {
            ratio = value + ":1";
        }
        this.setState({
            w: w,
            h: h,
            ratio: ratio
        }, () => {
            if (this.state.debug === true) {
                let size = w + " x " + h;
                d3.select('#debug').select(".first-line").html(ratio);
                d3.select('#debug').select(".second-line").html(size);
                
            }
        });
    }

    resize = () => {
        window.scrollTo(0,1);
        let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.updateSizeAndRatio(w, h);
        this.updateLocations();

        if (this.state.scroll !== this.state.locations[(this.state.section)]) {
            if (this.sectionsTemplate.current) {
                document.getElementById('MainTemplate').scrollTo(0, this.state.locations[(this.state.section)])
            }
        }
    }

    componentDidMount() {
        window.onresize = this.resize;
        this.resize();
    }

    render() {
        const classes = this.props.classes;
        
        return (
            <div>
                <NavControls classes={classes}
                    state={this.state}
                    scrollToSection={this.scrollToSection}
                    sectionsTemplateRef={this.sectionsTemplate}/>
                <Debug classes={classes} />
                <MainTemplate classes={classes}
                    state={this.state}
                    updateScroll={this.updateScroll}
                    updateLocations={this.updateLocations}
                    setBubblesData={this.setBubblesData}
                    ref={this.sectionsTemplate}/>
            </div>
         );
    }
}

export default MainFrame;
