import theme from '../theme';
import { makeStyles } from '@material-ui/core/styles';
import { autoType } from 'd3';

const materialClassFn = (theme) => {
    return (theme) => ({
        // debug
        debug: {
            position: "fixed",
            right: "10px",
            bottom: "10px",
            textAlign: "left",
            zIndex: "100",
        },
        // UTILS
        overlapContainer: {
            display: "grid",
            gridTemplateColumns: "1fr",
        },
        overlapped: {
            gridRowStart: "1",
            gridColumnStart: "1",
        },
        multiply: {
            mixBlendMode: "multiply",
        },    
        // NAV CONTROLS
        navControls: {
            position: "fixed",
            width: "calc(10%)",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            pointerEvents: "none",
            zIndex: "99",
        },
        triangleDown: {
            position: "relative",
            bottom: "20px",
            pointerEvents: "all",
            width: "40px",
            height: "40px",
            backgroundImage: 'url(imgs/arrow-down-circle.svg)',
            backgroundPosition: "center center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",

        },
        triangleUp: {
            position: "relative",
            top: "20px",
            pointerEvents: "all",
            width: "40px",
            height: "40px",
            backgroundImage: 'url(imgs/arrow-up-circle.svg)',
            backgroundPosition: "center center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        },
        leftRightTriangles: {
            width: "calc(100%)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
        },
        triangleLeft: {
            pointerEvents: "all",
            opacity: "1",
            borderLeft: "25px solid transparent",
            borderRight: "25px solid white",
            borderTop: "25px solid transparent",
            borderBottom: "25px solid transparent",
        },
        triangleRight: {
            pointerEvents: "all",
            opacity: "1",
            borderLeft: "25px solid white",
            borderRight: "25px solid transparent",
            borderTop: "25px solid transparent",
            borderBottom: "25px solid transparent",
        },
        blogLink: {
            fontSize: "0.7rem",
            padding: "30px",
        },
        // MAIN TEMPLATE
        info: {
            position: "absolute",
            top: "20px",
            right: "20px",
            width: "50px",
            height: "50px",
            borderRadius: "20px",
            zIndex: "10",
            opacity: "0.8",
            overflowY: "auto",
        },
        infoTitle: {
            display: "none",
        },
        openInfo: {
            display: "inherit",
            position: "relative",
            float: "right",
            width: "50px",
            height: "50px",
            backgroundImage: 'url(imgs/help-circle.svg)',
            backgroundPosition: "center center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        },
        closeInfo: {
            display: "none",
            position: "relative",
            float: "right",
            width: "50px",
            height: "50px",
            backgroundImage: 'url(imgs/x-circle.svg)',
            backgroundPosition: "center center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        },
        infoText: {
            display: "none",
        },
        sectionsContainer: {
            scrollSnapType: "y mandatory",
            overflowY: "scroll",
            height: "100vh",
            width: "100vw"
        },
        eachSection: {
            scrollSnapAlign: "start",
            width: "calc(100%)",
            height: "100%",
            fontSize: "1rem",
            overflow: "hidden",
        },
        // SECTION 1
        section1: {
            background: "#ffcc00",
            color: "black",
            height: "100vh",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            '& #sea-image': {
                width: "100%",
                height: "150px",
                '& svg': {
                    width: "100%",
                    height: "150px"
                }
            }
        },
        videoContainer: {
            gridColumnStart: 'start',
            gridColumnEnd: 'end',
            gridRowStart: 'start',
            gridRowEnd: 'end',
            width: "100%",
            height: "100%",
            display: "grid",
            gridTemplateColumns: "1fr",
            '& #video-ojo': {
                gridRowStart: "1",
                gridColumnStart: "1",
                width: "100%",
                height: "auto",
                mixBlendMode: "multiply",
                zIndex: "5",
            },
            '& #video-ojo-blanco': {
                gridRowStart: "1",
                gridColumnStart: "1",
                width: "100%",
                height: "auto",
                zIndex: "1",
            },
        },
        mainTitle: {
            width: "calc(min(100vh, 125vw))",
            height: "calc(calc(min(100vh, 125vw)) * 0.75)",
            display: "grid",
            gridTemplateColumns: "[start] 25% [l1] 50% [l2] 25% [end]",
            gridTemplateRows: "[start] 73% [l1] 27% [end]",
            backgroundColor: theme.palette.primary.main,
            color: "black",
        },
        titleAndSubtitle:{
            gridColumnStart: 'l1',
            gridColumnEnd: 'l2',
            gridRowStart: 'l1',
            gridRowEnd: 'end',
            width: "100%",
            height: "100%",
            display: "grid",
            gridTemplateColumns: "[start] 10% [l1] 26% [la] auto [lb] 42% [l2] 10% [end]",
            gridTemplateRows: "[start] 75% [l1] 25% [end]",
            justifyContent: "center",
            alignItems: "center",
        },
        signatureSvg: {
            gridColumnStart: 'start',
            gridColumnEnd: 'end',
            gridRowStart: 'start',
            gridRowEnd: 'end',
            width: "100%",
            height: "auto",
            '& path': {
                fill: theme.palette.sea.dark,
            },
        },
        subtitleContainer: {
            gridColumnStart: 'l1',
            gridColumnEnd: 'l2',
            gridRowStart: 'l1',
            gridRowEnd: 'end',
            width: "100%",
            height: "100%",
            '& path': {
                fill: theme.palette.sea.dark,
            },
        },
        subtitleImage: {
            width: "100%",
            height: "100%",
            gridColumnStart: 'la',
            gridColumnEnd: 'lb',
            gridRowStart: 'l1',
            gridRowEnd: 'end',
            '& img': {
                objectFit: "contain",
                width: "100%",
                height: "100%",
                transform: "scale(60%)",
            }
        },
        // SECTION 2
        section2: {
            background: "linear-gradient(to top, " + theme.palette.sea.dark + ", " + theme.palette.sea.light + ")",
            height: "100vh",
            '& #bubbles': {
                zIndex: "1",
            },
            '& #forces': {
                zIndex: "2",
            },
        },
        // SECTION 3
        section3: {
            background: theme.palette.earth.main,
            height: "100vh",
            display: "flex",
            alignItems: "top",
            justifyContent: "space-around",
            flexDirection: "row",
            flexWrap: "wrap",
        },
        cardsContainerV: {
            // width: "100%",
            height: "calc(100vh - 50px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "column",
        },
        cardsContainerH: {
            // width: "100%",
            height: "calc(100vh - 50px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "row",
        },
        // horizontal
        cardsContainerA: {
            transition: "top 0.5s, left 0.5s, width 0.5s, height 0.5s",
            width: "calc(100vh / 1.6)",
            height: "calc(100vh / 1.4)",
            // border: "2px dashed aqua",       
            '&:nth-child(1)': {
                transform: "rotate(-10deg)",
                position: "relative",
                top: "-10px",
                left: "-10px",
            },
            '&:nth-child(2)': {
                transform: "rotate(10deg)",
                position: "relative",
                top: "10px",
                left: "10px",
            },
        },
        // square
        cardsContainerB: {
            transition: "top 0.5s, left 0.5s, width 0.5s, height 0.5s",
            width: "calc(100vh / 2.2)",
            height: "calc(100vh/ 2.2)",
            // border: "2px dashed orange",
            '&:nth-child(1)': {
                transform: "rotate(-10deg)",
                position: "relative",
                top: "10px",
                left: "-20%",
            },
            '&:nth-child(2)': {
                transform: "rotate(10deg)",
                position: "relative",
                top: "-10px",
                left: "20%",
            },
        },
        // vertical
        cardsContainerC: {
            transition: "top 2s, left 2s, width 2s, height 2s",
            width: "calc(100vh / 2.5)",
            height: "calc(100vh / 2.5)",
            // border: "2px dashed lime",
            '&:nth-child(1)': {
                transform: "rotate(-10deg)",
                position: "relative",
                top: "10px",
                left: "-25px",
            },
            '&:nth-child(2)': {
                transform: "rotate(10deg)",
                position: "relative",
                top: "-10px",
                left: "25px",
            },
        },
        // CARDS CONTROL
        cardsGrid: {
            display: "grid",
            width: "100%",
            height: "100%",
            position: "relative",
            gridTemplateColumns: "[start] 10% [card-start] 10% [line1] 60% [line2] 10% [card-end] 10% [end]",
            gridTemplateRows: "[start] 35% [line1] 30% [line2] 35%",
            '& #prev': {
                backgroundImage: 'url(imgs/arrow-left-circle.svg)',
                backgroundPosition: "center center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                gridColumnStart: 'start',
                gridColumnEnd: 'line1',
                gridRowStart: 'line1',
                gridRowEnd: 'line2',
                display: "flex",
                alignItems: "center",
                zIndex: "10",
                opacity: "0.2",
                '&:hover':{
                    opacity: "1",
                },
                '& div': {
                    margin: "auto",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    opacity: "1",
                    borderRadius: "20px",
                },
            },
            '& #next': {
                backgroundImage: 'url(imgs/arrow-right-circle.svg)',
                backgroundPosition: "center center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                gridColumnStart: 'line2',
                gridColumnEnd: 'end',
                gridRowStart: 'line1',
                gridRowEnd: 'line2',
                display: "flex",
                alignItems: "center",
                zIndex: "10",
                opacity: "0.2",
                '&:hover':{
                    opacity: "1",
                },
                '& div': {
                    margin: "auto",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    opacity: "1",
                    borderRadius: "20px",
                },
            },
            '& #loader': {
                gridColumnStart: 'line1',
                gridColumnEnd: 'line2',
                gridRowStart: 'line1',
                gridRowEnd: 'line2',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "10",
            },
            '& #cardsDeck': {
                gridColumnStart: 'card-start',
                gridColumnEnd: 'card-end',
                gridRowStart: 'start',
                gridRowEnd: 'end',
                alignItems: "center",
                // perspective: "900px",
                perspective: "calc(min(100vw, 100vh))",
                '& .flip-card, ': {
                    transition: "500ms",
                    position: "relative",
                    transform: "rotateX(0deg)",
                    textAlign: "center",
                    transitionTimingFunction: "cubic-bezier(0,.57,.99,.44)",
                },
                '& .flip-card-front, .flip-card-back': {
                    width: "100%",
                    height: "100%",
                    margin: "auto",
                    // backgroundColor: "#ffcc00",
                },
                '& #card1': {
                    //
                },
                '& #card2, #card3': {
                    width: "100%",
                    height: "inherit",
                    transform: "rotateY(180deg)",
                },
                // '& #card2': {
                //     backgroundColor: "#18CBD6",
                // },
                // '& #card3': {
                //     backgroundColor: "#253E75",
                // },
                '& .flip-card-front, .flip-card-back-p .flip-card-back-n': {
                    backfaceVisibility: "hidden",
                },
            },
        },
        /////////////// CARD POST
        card: {
            width: "100%",
            height: "100%",
            borderRadius: "20px",
            overflow: "clip",
            transform: "scale(0.95)",
            display: "grid",
            gridTemplateColumns: "[start] 100% [end]",
            gridTemplateRows: "[start] 10% [l0] 15% [l1] 65% [l2] 10% [end]",
            justifyContent: "center",
            alignItems: "center",
        },
        loaderIcon: {
            fontSize: "5rem",
            animation: "rotation 2s infinite linear"
        },
        postTitle: {
            gridColumnStart: 'start',
            gridColumnEnd: 'end',
            gridRowStart: 'start',
            gridRowEnd: 'l1',
        },
        postContent: {
            gridColumnStart: 'start',
            gridColumnEnd: 'end',
            gridRowStart: 'l1',
            gridRowEnd: 'l2',
            background: "lightgreen",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100%",
            overflowY: "auto",
            color: "white",
            '& > div': {
                padding: "20px",
            },
        },
        postDate: {
            gridColumnStart: 'start',
            gridColumnEnd: 'end',
            gridRowStart: 'l2',
            gridRowEnd: 'end',
        },
        // SECTION 4
        section4: {
            background: theme.palette.dark.main,
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "black",
        },
        mainFooter: {
            width: "80%",
            display: "flex",
            flexDirection: "column",
            color: "white",
            background: "black",
        },
        titleFooter:{
            transform: 'scale(0.6)',
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
        },
        finalText: {
            paddingBottom: "40px",
            transform: 'scale(0.8)',
        },
        lastWordsTitle: {
            //
        },
        lastWords: {
            // paddingTop: "200px",
        },
    });
};

const useStyles = makeStyles(materialClassFn(theme));

export default useStyles;