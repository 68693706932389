import React, { Component } from 'react';

class AnimatedEye extends Component {
    
    render() {
        const classes = this.props.classes;
        return (
            <div className={classes.videoContainer}>
                <img id="video-ojo" alt="eye" src="./imgs/ojo/0001.jpg"></img>
                <img id="video-ojo-blanco" alt="eye" src="./imgs/cornea/0001.png"></img>
            </div>
         );
    }
}

export default AnimatedEye;
