import React, { Component } from 'react';

import * as d3 from "d3";

class NavControls extends Component {

    constructor(props) {
        super(props);
        this.classes = this.props.classes;
    }

    componentDidMount() {

        d3.select('.' + this.classes.triangleDown).style('opacity', '1');
        d3.select('.' + this.classes.triangleUp).style('opacity', '1');
        if (this.props.state.section === 0) {
            d3.select('.' + this.classes.triangleUp).style('opacity', '0');
        } else if (this.props.state.section === 3) {
            d3.select('.' + this.classes.triangleDown).style('opacity', '0');
        }
    }
    

    render() {
        const classes = this.props.classes;
        const scrollToSection = this.props.scrollToSection;
        let state = this.props.state;
        let section = state.section;

        const clickDown = function() {
            section = section === 3 ? 3 : section + 1;
            scrollToSection(section);
        }

        const clickUp = function() {
            section = section === 0 ? 0 : section - 1;
            scrollToSection(section);
        }
        
        return (
            <div className={classes.navControls}>
                <div onClick={clickUp} className={classes.triangleUp}></div>
                <div onClick={clickDown} className={classes.triangleDown}></div>
            </div>
         );
    }
}

export default NavControls;
