import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import AvatarAndName from './AvatarAndName';

class CardBlogPost extends Component {

    constructor(props) {
        super(props);
        this.content = React.createRef();
    }

    createMarkup(html) {
        return { __html: html };
    };

    htmlToText(htmlString) {
        const el = document.createElement('div');
        let text = htmlString.__html.slice(0, 90) + '...'
        el.innerHTML = text;
        return el.textContent === "..." ? "" : el.textContent;
    }

    setImage(data) {
        let imgSource = "";
        if (data._embedded["wp:featuredmedia"] && data._embedded["wp:featuredmedia"].length > 0) {
            imgSource = data._embedded["wp:featuredmedia"][0].source_url;
        }

        if (imgSource !== "" && this.content.current !== null) {
            this.content.current.style.backgroundImage = 'url(' + imgSource + ')';
        }


        return imgSource
    }

    getText(data) {
        return this.htmlToText(this.createMarkup(data));
    }

    render() {
        const classes = this.props.classes;
        const data = this.props.data;
        let content = null;
        
        this.setImage(data);
    
        if (this.props.postType === 'posts') {
            content = <div>{this.getText(data.excerpt.rendered)}</div>
        }

        return (
            <Card className={classes.card}>
                <div className={classes.postTitle}>
                    {data.title.rendered}
                </div>
                <div ref={this.content} className={classes.postContent}>
                    {content}
                </div>
                <div className={classes.postDate}>
                    <AvatarAndName classes={classes}
                            date={data.date}
                            postType={this.props.postType}/>
                </div>
            </Card>
         );
    }
}

export default CardBlogPost;
