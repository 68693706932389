import { drawCircles, drawEdges } from './drawCircles.js';
import { node_data, edge_data } from './data.js';
import * as d3 from "d3";

export function drawForce(container, w, h, data) {
    d3.select(container).selectAll('*').remove();

    const svg = d3.select(container).append('svg')
        .attr('class', 'svg-container')
        .attr('width', w - 5)
        .attr('height', h - 5);

    let link_dist = w / 5;
    let radiusScale = Math.min(w / 200, h / 200);

    let nodes;
    let edges;

    if (!data) {
        // set up data
        nodes = node_data.map(d => Object.create(d));
        edges = edge_data.map(d => Object.create(d));
    } else {
        // set up data
        nodes = data.nodes;
        edges = data.links;
    }

    const forceCenter = d3.forceCenter(w / 2, h / 2).strength(0.3);

    const changecenter = function(point) {
        forceCenter.x(point.x);
        forceCenter.y(point.y);
    }

    // const makeCircularIterator = function() {
    //     let iterationCount = 0;
    //     const circularIterator = {
    //        next: function() {
    //            let time = Date.now() / 5000;
    //            let position = { x: (Math.sin(time) * 100) + (w / 2), y: (Math.cos(time) * 100) + (h / 2)}
    //            return position;
    //        }
    //     };
    //     return circularIterator;
    // }

    // const iterator = makeCircularIterator();

    // function boxingForce() {
    //     const radiusX = w/2 ;
    //     const radiusY = h/2 ;
  
    //     for (let node of nodes) {
    //         let r = Math.ceil(node.r);
    //         let rangeX = [Math.max(0 + r, node.x ) , Math.min(w - r, node.x)];
    //         let rangeY = [Math.max(0 + r, node.y ) , Math.min(h - r, node.y)];
    //         //console.log(rangeX, rangeY);
    //         // node.x = Math.max(rangeX[0], rangeX[1]);
    //         // node.y = Math.max(rangeY[0], rangeY[1]);
    //         // node.x = Math.max(-radiusX+r, Math.min(radiusX-r, node.x));
    //         // node.y = Math.max(-radiusY+r, Math.min(radiusY-r, node.y));
    //     }
    // }

    // define simulation forces
    const sim = d3.forceSimulation(nodes)
        .force('charge', d3.forceManyBody().strength(-150))
        .force("link", d3.forceLink(edges).distance(link_dist).strength(0.1))
        .force("collide", d3.forceCollide().radius(d => d.value * radiusScale + 10))
        .force("center", forceCenter)
        // .force("bounds", boxingForce)
        // .stop()
        .on('tick', function(){
            // changecenter(iterator.next())
            updateDraw();
        })
        .on('end', function() {
            sim.tick();
            sim.restart();
        });

    const updateDraw = function() {
        drawEdges(svg, nodes, edges);
        drawCircles(svg, radiusScale, sim);
    }

    drawEdges(svg, nodes, edges);
    drawCircles(svg, radiusScale, sim, nodes);

    window.addEventListener('resize', function() {
        w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        svg
            .attr('width', w - 5)
            .attr('height', h - 5);

        changecenter({x: w / 2, y: h / 2});
    });
}
