import React, {useRef, useEffect, useState} from "react";
import {Runtime, Inspector} from "@observablehq/runtime";
import define from "@surreal6/bubbles";

function Bubbles(props) {
    const chartRef = useRef();
    const [main, setMain] = useState(null)

    useEffect(() => {
        // ??? arreglar warning
        if (main && main.redefine) {
            main.redefine("width", props.w);
            main.redefine("height", props.h);
        }
    },  [props.w, props.h])

    useEffect(() => {
        const mainBubbles = new Runtime().module(define, name => {
            if (name === 'chart') return new Inspector(chartRef.current);
            if (name === "pointermoveEvent") return true;
        });

        mainBubbles.redefine("width", props.w);
        mainBubbles.redefine("height", props.h);

        setMain(mainBubbles);

        return () => {
            // mainBubbles.dispose();
        }
    }, []);

    

    return (
        <div id="bubble-chart" ref={chartRef}/>
    );
}

export default Bubbles;