import React from 'react';
import MainFrame from './components/MainFrame';

//my styles
import useStyles from './styles/materialClass';

function App() {
     const classes = useStyles();

    return (
        <div className="App">
            <MainFrame classes={classes}/>
      </div>
    );
}

export default App;
