import React, { Component } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import * as d3 from "d3";

class AvatarAndName extends Component {
    render() {
        const classes = this.props.classes;
        const parseDatePosts = d3.timeFormat("%B %d, %Y");
        const parseDate = d3.timeFormat("%Y");

        let date = null;
        let content = null;
        if (this.props.postType === 'posts') {
            date = parseDatePosts(new Date(this.props.date));
            content = <React.Fragment>
                <Typography variant="subtitle2" color="textSecondary" component="p">
                    {date}
                </Typography>
            </React.Fragment>
        } else {
            date = parseDate(new Date(this.props.date));
            content = <Typography variant="h5" component="p">
                    {date}
                </Typography>
        }


        return (
            <Box className={classes.author}>
                {content}
            </Box>
         );
    }
}

export default AvatarAndName;
