import { debounce } from '@material-ui/core';
import React, { Component } from 'react';

import * as d3 from "d3";

import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';

class MainTemplate extends Component {

    constructor(props) {
        super(props);
        this.element = React.createRef();
        this.info = React.createRef();
        this.openInfo = React.createRef();
        this.closeInfo = React.createRef();
    }

    getSectionLocations = () => {
        let locationsArray = [];
        let offset = 0;
        for (let index = 0; index < 4; index++) {
            const element = "#section" + (index + 1);
            if (index === 0) {
                offset = -document.querySelector(element).getBoundingClientRect().top;
            }
            locationsArray[index] = Math.round(document.querySelector(element).getBoundingClientRect().top + offset);
        }
        return locationsArray;
    }

    updateScroll = () => {
        let positionFromTop = document.querySelector('#section1').getBoundingClientRect().top;
        this.props.updateScroll(Math.abs(parseInt(positionFromTop)));
    };

    updateScrollEvent = debounce(() => this.updateScroll())

    componentDidMount() {
        this.props.updateLocations();
        this.element.current.addEventListener('scroll', this.updateScrollEvent);

        let info = this.info.current;
        let openInfo = this.openInfo.current;
        let closeInfo = this.closeInfo.current;

        openInfo.addEventListener('click', function(e) {
            openInfo.style.display = "none";
            d3.select(info).style('background', 'white');
            d3.select(info).style('width', "calc(70vw - 40px)");
            d3.select(info).style('height', "calc(90% - 40px)");
            d3.select(info).style('padding', "20px");
            closeInfo.style.display = "inline-block";
            d3.select(info).select('h1').style("display", 'block');
            d3.select(info).select('p').style("display", 'block');
        });

        closeInfo.addEventListener('click', function(e) {
            openInfo.style.display = "inline-block";
            d3.select(info).style('background', '');
            d3.select(info).style('width', "50px");
            d3.select(info).style('height', "50px");
            closeInfo.style.display = "none";
            d3.select(info).select('h1').style("display", 'none');
            d3.select(info).select('p').style("display", 'none');
        });
    }

    componentDidUpdate() {
        let section = this.props.state.section;

        let title = d3.select(this.info.current).select('h1');
        let text = d3.select(this.info.current).select('p');

        const innerText = [
            "<h2>Scroll animado</h2> Mi intención en esta página es crear secciones con un scroll limitado y que siempre ocupen el total de la pantalla, pero que puedan ser navegadas con animación.<br> Con los iconos del lateral izquierdo se puede hacer scroll animado sobre la página, aunque esta 'feature' por algun motivo aún no funciona en dispositivos macOs<h2>El ojo</h2>El viejo truco de cargar imagenes ligeras en memoria, combinado con modos de fusion css, y reaccionando a la posición del ratón<h2>El mar</h2>Un mar procedural hecho con d3",
            "Aquí un ejemplo de componentes reactivos<h2>Burbujas</h2>Las burbujas que se ven al fondo son un Notebook de ObservableHQ cargadas como módulo e instanciadas como componente de React.<h2>Force Graph</h2>El diagrama de fuerzas que hay encima es una visualización de las categorías de mi portfolio (obtenidas en tiempo de ejecución a través de la api de wordpress), representadas como gráfico dirigido por fuerzas dibujado con d3.",
            "<h2>Mazo de cartas</h2>Aquí se muestra un componente de react diseñado por mí desde cero. Recoge los posts de mi blog y portfolio (una vez más utilizando la api de wordpress) y los muestra como si fuesen un mazo de cartas.<h4>WIP</h4>Las imágenes de cada post deberían ser cargadas en preload y parece que hay algún fallo en la sincronía de las animaciones, este componente es un 'work in progress'. Además, aun no encontré como cargar con la api de WP imagenes reducidas de cada post.<h2>Aspect Ratio</h2>Los dos mazos de cartas se reposicionan en función del ratio de la pantalla (ancho/alto). Mi intención era experimentar con un responsive no basado en los clásicos media mixins, y practicar el diseño basado en flex y grid layout.",
            "Esta página es un prototipo, un cajón de arena en el que vengo practicando experimentos autodidactas con react, d3, observable y css.<br><br>Le he dedicado aproximadamente un mes entre Febrero y Marzo y una semana en Agosto para ponerlo algo más presentable. Antes de esta página no tenía experiencia previa con react.<br><br>Admito haber implementado algunas cosas con d3 para que funcionen, aunque seguramente react tenga métodos más adecuados para obtener esos resultados, pero como digo, esta página es un prototipo y ha sido mi primera aproximación a react."
        ]

        title.node().innerHTML = 'Sección ' + (section + 1);
        text.node().innerHTML = innerText[section];
    }

    render() {
        const classes = this.props.classes;
        const state = this.props.state;

        return (
            <div id="MainTemplate" className={classes.sectionsContainer} ref={this.element}>
                <div className={classes.info} ref={this.info}>
                    <div className={classes.openInfo} ref={this.openInfo}></div>
                    <div className={classes.closeInfo} ref={this.closeInfo}></div>
                    <h1 className={classes.infoTitle} >titulo</h1>
                    <p className={classes.infoText} >texto mas o menos largo que va dentro del div</p>
                </div>
                <div id="section1" className={`${classes.section1} ${classes.eachSection}`}>
                    <Section1 state={state} classes={classes}/>
                </div>
                <div id="section2" className={`${classes.section2} ${classes.eachSection}`}>
                    <Section2 classes={classes}
                        w={state.w} h={state.h}
                        setBubblesData={this.props.setBubblesData}/>
                </div>
                <div id="section3" className={`${classes.section3} ${classes.eachSection}`}>
                    <Section3 state={state} classes={classes}/>
                </div>
                <div id="section4" className={`${classes.section4} ${classes.eachSection}`}>
                    <Section4 classes={classes}/>
                </div>
            </div>
         );
    }
}

export default MainTemplate;
