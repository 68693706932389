import React, { Component } from 'react';

import Signature from './Signature';
import Typography from '@material-ui/core/Typography';

class Section4 extends Component {

    render() { 
        const classes = this.props.classes;

        const lastWords = "Esta página es un prototipo, un cajón de arena en el que vengo practicando experimentos autodidactas con react, d3, observable y css. Le he dedicado un mes entre Febrero y Marzo y una semana en Agosto. Antes de eso no tenía experiencia previa con react.";

        return ( 
            <React.Fragment>
                <div className={classes.mainFooter}>
                    <div className={classes.titleFooter}>
                        <img src="imgs/signature.svg"></img>
                        <img src="imgs/subtitle-min-bn.svg"></img>                        
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default Section4;