import * as d3 from "d3";

export function drawCircles(container, radiusScale, sim, data) {
    const palette = [ "#f28e2c", "#e15759", "#59a14f", "#edc949", "#ff9da7" ];
    const enterCircles = function(selection) {
        selection
            .append('circle')
            .attr('index', d => d.index)
            .attr('cx', 0)
            .attr('cy', 0)
            .attr('r', d => d.value * radiusScale + 5)
            .style('fill', d => randomColor(palette))

        selection
            .call(drag(sim))
            .on("click", clicked);

        selection.append('text')
            .text((d) => d.name)
            .attr('text-anchor', 'middle');
    };

    function clicked(event) {
        const [x, y] = d3.pointer(event);
        console.log("circle cliked in " + x + " " + y);
        d3.select('.bubble-container').dispatch(event);
    }

    const updateCircles = function(selection) {
        selection
            .attr("transform", d => {
                return 'translate(' + d.x + ',' + d.y + ')';
            });

        selection.selectAll('circles')
            .attr('r', d => d.value * radiusScale + 3);
    };

    const drag = function(simulation) {
        function dragstarted(event, d) {
            d3.select(this).select('circle').style('fill', 'aqua');

            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
        }

        function dragged(event, d) {
            d.fx = event.x;
            d.fy = event.y;
        }

        function dragended(event, d) {
            d3.select(this).select('circle').style('fill', d => randomColor(d3.schemeTableau10));
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }

        return d3.drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended);
    }

    // Start d3 join
    if (data) {
        const update = container.selectAll('g')
            .data(data, d => d.index);
        const enter = update.enter()
        .append('g')
        .call(enterCircles);

        update.merge(enter)
            .call(updateCircles);

        update.exit().remove();
    } else {
        container.selectAll('g')
            .call(updateCircles);
    }

};

export function drawEdges(container, node_data, data) {
    const enterEdges = function(selection) {
        selection
        .each(function(d) {
        })
        .classed("edge", true)
        .attr("x1", d => node_data[d.source.index].x)
        .attr("y1", d => node_data[d.source.index].y)
        .attr("x2", d => node_data[d.target.index].x)
        .attr("y2", d => node_data[d.target.index].y)
        .style("stroke", 3 ? "#bbb" : "none")
        .style('fill', d => randomColor(d3.schemeTableau10))
    };

    const updateEdges = function(selection) {
        selection
            .each(function(d) {
            })
            .attr("x1", d => node_data[d.source.index].x)
            .attr("y1", d => node_data[d.source.index].y)
            .attr("x2", d => node_data[d.target.index].x)
            .attr("y2", d => node_data[d.target.index].y);
    };

    // Start d3 join
    if (data) {
        const update = container.selectAll('line')
            .data(data, d => d.index);

        const enter = update.enter()
            .append('line')
            .call(enterEdges);

        update.merge(enter)
            .call(updateEdges);

        update.exit().remove();
    } else {
        container.selectAll('line')
            .call(updateEdges);
    }
};

export function randomColor(colorSet) {
    return colorSet[parseInt(Math.random()* colorSet.length-1)];
};
