import React, { Component } from 'react';

class Subtitle extends Component {
    render() {
        const classes = this.props.classes;
        
        return (
            <React.Fragment>
                <svg version="1.1" viewBox="0 0 850 50" className={classes.subtitleContainer}>
                    <g transform="translate(-100.85 -352.76)" imageRendering="auto">
                        <g strokeWidth="1.279" aria-label="visual artist">
                            <path 
                                d="m105.65 366.36h7.0695l6.0952 17.187 5.8954-17.187h6.9196l-10.317 26.105h-5.146z"
                                />
                            <path
                                d="m134.4 358.92q1.9984-3.1226 4.1718-5.4957l5.3708 4.2217q-1.0742 1.324-1.9984 2.598 0 0-1.9235 2.7229zm6.5699 30.751q0 2.1483 0.0999 2.7978h-6.345v-26.105h6.2451z"
                                />
                            <path
                                d="m156.78 392.84q-7.0445 0-10.617-2.573 0.05-0.74942 0.14988-1.6987l0.22482-1.7986q0.19985-1.7736 0.24981-2.1983 0.05-0.44965 0.0749-0.47463 0.025-0.05 0.39969 0.39969 0.37471 0.42467 1.4239 1.0492t2.4231 1.0991q2.9727 1.0242 6.1952 1.0242 4.3216 0 4.3216-2.8728 0-0.92428-0.82436-1.3739-1.4738-0.74942-4.3466-1.1241-2.8478-0.39969-4.6714-1.1491-1.8236-0.74942-2.9727-1.7986-2.1233-1.9735-2.1233-5.171 0-3.897 2.9977-6.1202 2.9727-2.1983 8.2186-2.1983 5.196 0 8.8681 2.9477-0.17486 0.6495-0.37471 1.249-0.57455 1.6487-0.84933 2.3732l-0.57455 1.4738q-0.05 0.19984-0.075 0.19984-0.05 0-0.39968-0.52459-1.5738-1.8236-4.8962-2.3981-1.0242-0.19984-2.0984-0.19984-4.5464 0-4.5464 2.7478 0 0.94926 0.94926 1.4489 1.7736 0.94926 4.8462 1.3989 3.0976 0.44965 4.6963 1.224 1.5988 0.74941 2.573 1.8236 1.7986 1.9485 1.7986 5.171 0 3.5223-2.8228 5.7205-2.9477 2.3232-8.2186 2.3232z"
                                />
                            <path
                                d="m189.31 390.19q-3.1975 2.6479-6.9696 2.6479-4.0468 0-6.345-2.7229-2.1983-2.6479-2.1983-7.2693v-9.9173q0-4.9961-0.22483-5.7205-0.19984-0.74942-0.29976-0.94926 1.1491 0.0999 2.3981 0.0999h2.0734q0.82436 0 2.1733-0.0749v14.464q0 5.146 2.7978 5.9703 0.84934 0.24981 1.8486 0.24981 2.9477 0 4.7463-2.1483v-18.461h6.1952v22.907q0 2.1983 0.19984 3.1975h-6.395z"
                                />
                            <path
                                d="m220.68 390.92q-3.5472 1.9235-6.42 1.9235t-4.9212-0.52459q-2.0484-0.54957-3.4723-1.5988-2.8977-2.1234-2.8977-6.1452 0-3.5972 3.5472-5.9953 3.847-2.598 10.442-2.598l0.99922 0.025q0.29977 0.025 0.67447 0.05 0.37471 0 0.67448 0.05-0.19985-3.9969-3.4473-4.6714-1.0742-0.22483-2.2982-0.22483-1.1991 0-2.3732 0.22483-1.1491 0.19984-2.2482 0.54957-2.4231 0.77439-3.4723 1.7736l-0.0749-0.05-0.74942-5.5207q4.4216-2.3232 9.6675-2.3232 8.1436 0 10.417 5.8704 0.72443 1.8735 0.72443 4.2217v9.043q0 2.8977 2.0484 3.9469l-4.5215 4.1468q-1.4239-0.74942-2.2982-2.1733zm-1.349-10.317q-0.67448-0.12491-1.3989-0.12491h-0.99922q-5.5207 0-7.0195 2.4481-0.44965 0.72443-0.44965 1.5238 0 0.79937 0.27479 1.4239 0.29977 0.59953 0.97424 0.99922 1.299 0.74941 3.847 0.74941 2.573 0 4.7963-1.224-0.025-0.27479-0.025-0.57455z"
                                />
                            <path d="m239.02 389.67q0 2.1483 0.0999 2.7978h-6.345v-37.221h6.2451z"
                                />
                            <path
                                d="m280.24 390.92q-3.5472 1.9235-6.42 1.9235t-4.9212-0.52459q-2.0484-0.54957-3.4723-1.5988-2.8977-2.1234-2.8977-6.1452 0-3.5972 3.5472-5.9953 3.847-2.598 10.442-2.598l0.99922 0.025q0.29977 0.025 0.67447 0.05 0.37471 0 0.67448 0.05-0.19985-3.9969-3.4473-4.6714-1.0742-0.22483-2.2982-0.22483-1.1991 0-2.3732 0.22483-1.1491 0.19984-2.2482 0.54957-2.4231 0.77439-3.4723 1.7736l-0.0749-0.05-0.74942-5.5207q4.4216-2.3232 9.6675-2.3232 8.1436 0 10.417 5.8704 0.72443 1.8735 0.72443 4.2217v9.043q0 2.8977 2.0484 3.9469l-4.5215 4.1468q-1.4239-0.74942-2.2982-2.1733zm-1.349-10.317q-0.67448-0.12491-1.3989-0.12491h-0.99922q-5.5207 0-7.0195 2.4481-0.44965 0.72443-0.44965 1.5238 0 0.79937 0.27479 1.4239 0.29977 0.59953 0.97424 0.99922 1.299 0.74941 3.847 0.74941 2.573 0 4.7963-1.224-0.025-0.27479-0.025-0.57455z"
                                />
                            <path
                                d="m298.67 389.67q0 2.1483 0.0999 2.7978h-6.2202v-26.105h6.1202v1.9235q2.1233-2.4231 4.4465-2.4231 2.3482 0 3.6971 0.57455l-0.44965 6.7198-0.0999 0.0749q-0.87432-1.5488-3.6222-1.5488-1.0492 0-2.1483 0.72444-1.0742 0.69945-1.8236 1.8735z"
                                />
                            <path
                                d="m321.45 386.97q3.2225 0 4.4216-1.6737h0.19984l0.075 5.7955q-1.224 1.224-4.5465 1.6237-0.8993 0.1249-2.2482 0.1249-1.324 0-2.8977-0.49961-1.5488-0.49961-2.6479-1.6237-2.2732-2.3232-2.2732-7.2693v-11.341h-3.7721q-0.025-0.72443-0.025-1.4738v-3.847q0-0.42467 0.025-0.42467h3.7721v-7.1194h6.1202v7.1194h6.8696v5.7455h-6.8696v10.192q0 3.9219 2.1733 4.4715 0.69946 0.19985 1.6237 0.19985z"
                                />
                            <path
                                d="m330.45 358.92q1.9984-3.1226 4.1718-5.4957l5.3708 4.2217q-1.0742 1.324-1.9984 2.598 0 0-1.9235 2.7229zm6.5699 30.751q0 2.1483 0.0999 2.7978h-6.345v-26.105h6.2451z"
                                />
                            <path
                                d="m352.83 392.84q-7.0445 0-10.617-2.573 0.05-0.74942 0.14988-1.6987l0.22482-1.7986q0.19985-1.7736 0.24981-2.1983 0.05-0.44965 0.0749-0.47463 0.025-0.05 0.39969 0.39969 0.37471 0.42467 1.4239 1.0492t2.4231 1.0991q2.9727 1.0242 6.1952 1.0242 4.3216 0 4.3216-2.8728 0-0.92428-0.82436-1.3739-1.4738-0.74942-4.3466-1.1241-2.8478-0.39969-4.6714-1.1491-1.8236-0.74942-2.9727-1.7986-2.1233-1.9735-2.1233-5.171 0-3.897 2.9977-6.1202 2.9727-2.1983 8.2186-2.1983 5.196 0 8.8681 2.9477-0.17486 0.6495-0.37471 1.249-0.57455 1.6487-0.84933 2.3732l-0.57455 1.4738q-0.05 0.19984-0.075 0.19984-0.05 0-0.39968-0.52459-1.5738-1.8236-4.8962-2.3981-1.0242-0.19984-2.0984-0.19984-4.5464 0-4.5464 2.7478 0 0.94926 0.94926 1.4489 1.7736 0.94926 4.8462 1.3989 3.0976 0.44965 4.6963 1.224 1.5988 0.74941 2.573 1.8236 1.7986 1.9485 1.7986 5.171 0 3.5223-2.8228 5.7205-2.9477 2.3232-8.2186 2.3232z"
                                />
                            <path
                                d="m381.31 386.97q3.2225 0 4.4216-1.6737h0.19985l0.0749 5.7955q-1.224 1.224-4.5465 1.6237-0.89929 0.1249-2.2482 0.1249-1.324 0-2.8977-0.49961-1.5488-0.49961-2.6479-1.6237-2.2732-2.3232-2.2732-7.2693v-11.341h-3.7721q-0.025-0.72443-0.025-1.4738v-3.847q0-0.42467 0.025-0.42467h3.7721v-7.1194h6.1202v7.1194h6.8696v5.7455h-6.8696v10.192q0 3.9219 2.1733 4.4715 0.69945 0.19985 1.6237 0.19985z"
                                />
                        </g>
                        <g strokeWidth="1.279" aria-label="creative developer">
                    <path
                        d="m525.45 390.05q-2.7978 2.7978-8.7682 2.7978-5.7955 0-9.4676-3.5222-3.7221-3.5722-3.7221-9.4926 0-6.0203 3.6721-9.8923 3.897-4.0718 10.417-4.0718 4.6214 0 7.744 2.0234l-0.99922 6.395-0.17487-0.025q-1.1991-1.4489-2.523-1.8985-2.1483-0.74941-4.2966-0.74941-2.1483 0-3.3724 0.54957-1.224 0.54957-2.0984 1.5238-1.7486 1.9984-1.7486 5.2959 0 5.8454 4.0468 7.644 1.3989 0.59953 2.9477 0.59953 1.5738 0 2.7229-0.27478 1.1741-0.27479 2.1234-0.74942 2.0234-0.99922 2.7478-2.3232l0.17487 0.0749z"
                        />
                    <path
                        d="m536.61 389.68q0 2.1483 0.0999 2.7978h-6.2202v-26.105h6.1202v1.9235q2.1234-2.4231 4.4465-2.4231 2.3482 0 3.6971 0.57455l-0.44965 6.7198-0.0999 0.0749q-0.87432-1.5488-3.6222-1.5488-1.0492 0-2.1483 0.72444-1.0742 0.69945-1.8236 1.8735z"
                        />
                    <path
                        d="m546.56 379.63q0-2.9227 0.99922-5.4208t2.8228-4.3716q3.897-3.9719 10.192-3.9719 5.3208 0 8.5433 3.5222 3.1725 3.3974 3.1725 8.5433 0 1.9735-0.29976 2.6979-2.4981 0.69945-10.167 0.69945h-8.7932q0.6495 2.6729 3.0226 4.1718 2.3731 1.4738 6.1702 1.4738 3.9719 0 6.8197-1.4239 0.74942-0.37471 1.1241-0.69946-0.05 1.0492-0.1249 2.1483l-0.27479 3.897q-1.8985 1.299-6.1452 1.7986-1.249 0.14988-2.3981 0.14988-6.345 0-10.492-3.6971-4.1718-3.7221-4.1718-9.5176zm19.085-3.1476q-1.0242-5.3708-5.8454-5.3708-3.797 0-5.7705 3.2724-0.59953 0.99922-0.92428 2.1983 0.59954 0.025 1.4739 0.05h1.8486q0.92428 0.025 1.7736 0.025h1.4239q1.274 0 2.498-0.025l2.0484-0.0749q0.82436-0.025 1.4738-0.0749z"
                        />
                    <path
                        d="m594.92 390.93q-3.5472 1.9235-6.42 1.9235t-4.9212-0.52459q-2.0484-0.54957-3.4723-1.5988-2.8977-2.1233-2.8977-6.1452 0-3.5972 3.5472-5.9953 3.847-2.598 10.442-2.598l0.99922 0.025q0.29977 0.025 0.67447 0.05 0.37471 0 0.67448 0.05-0.19985-3.9969-3.4473-4.6714-1.0742-0.22483-2.2982-0.22483-1.1991 0-2.3732 0.22483-1.1491 0.19984-2.2482 0.54957-2.4231 0.77439-3.4723 1.7736l-0.0749-0.05-0.74942-5.5207q4.4216-2.3232 9.6675-2.3232 8.1436 0 10.417 5.8704 0.72443 1.8735 0.72443 4.2217v9.043q0 2.8977 2.0484 3.9469l-4.5215 4.1468q-1.4239-0.74941-2.2982-2.1733zm-1.349-10.317q-0.67448-0.12491-1.3989-0.12491h-0.99922q-5.5207 0-7.0195 2.4481-0.44965 0.72444-0.44965 1.5238 0 0.79937 0.27479 1.4239 0.29977 0.59954 0.97424 0.99923 1.299 0.74941 3.847 0.74941 2.573 0 4.7963-1.224-0.025-0.27479-0.025-0.57456z"
                        />
                    <path
                        d="m619.05 386.98q3.2225 0 4.4216-1.6737h0.19984l0.075 5.7955q-1.224 1.224-4.5465 1.6237-0.8993 0.1249-2.2482 0.1249-1.324 0-2.8977-0.49961-1.5488-0.49961-2.6479-1.6237-2.2732-2.3232-2.2732-7.2693v-11.341h-3.7721q-0.025-0.72444-0.025-1.4738v-3.847q0-0.42467 0.025-0.42467h3.7721v-7.1194h6.1202v7.1194h6.8696v5.7455h-6.8696v10.192q0 3.9219 2.1733 4.4715 0.69946 0.19985 1.6237 0.19985z"
                        />
                    <path
                        d="m628.04 358.93q1.9984-3.1226 4.1718-5.4957l5.3708 4.2217q-1.0742 1.324-1.9984 2.598 0 0-1.9235 2.7229zm6.5699 30.751q0 2.1483 0.0999 2.7978h-6.345v-26.105h6.2451z"
                        />
                    <path d="m637.86 366.37h7.0695l6.0952 17.187 5.8954-17.187h6.9196l-10.317 26.105h-5.146z"
                        />
                    <path
                        d="m666.16 379.63q0-2.9227 0.99922-5.4208t2.8228-4.3716q3.897-3.9719 10.192-3.9719 5.3208 0 8.5433 3.5222 3.1725 3.3974 3.1725 8.5433 0 1.9735-0.29976 2.6979-2.4981 0.69945-10.167 0.69945h-8.7932q0.6495 2.6729 3.0226 4.1718 2.3731 1.4738 6.1702 1.4738 3.9719 0 6.8197-1.4239 0.74942-0.37471 1.1241-0.69946-0.05 1.0492-0.1249 2.1483l-0.27479 3.897q-1.8985 1.299-6.1452 1.7986-1.249 0.14988-2.3981 0.14988-6.345 0-10.492-3.6971-4.1718-3.7221-4.1718-9.5176zm19.085-3.1476q-1.0242-5.3708-5.8454-5.3708-3.797 0-5.7705 3.2724-0.59953 0.99922-0.92428 2.1983 0.59954 0.025 1.4739 0.05h1.8486q0.92428 0.025 1.7736 0.025h1.4239q1.274 0 2.498-0.025l2.0484-0.0749q0.82436-0.025 1.4738-0.0749z"
                        />
                    <path
                        d="m732.19 391.08q-3.1725 1.7736-6.345 1.7736-3.1725 0-5.5457-0.99922t-4.0468-2.7479q-3.4473-3.5972-3.4473-9.3427 0-5.9454 3.9969-9.8923 4.0219-3.9969 10.292-3.9969 1.9235 0 3.7721 0.49961v-11.116h6.1202v26.704q0 3.3224 0.29977 4.3466 0.32475 1.0242 0.69945 1.4988 0.39969 0.47463 0.99923 0.8993l-4.6963 4.1468q-1.299-0.69946-2.0984-1.7736zm-5.171-3.9719q2.0984 0 3.847-0.79938v-13.839q-1.7486-0.72444-3.797-0.72444t-3.5222 0.54957q-1.4489 0.54957-2.4981 1.5238-2.1983 2.0484-2.1983 5.3208 0 3.5722 1.9984 5.6956 2.1483 2.2732 6.1702 2.2732z"
                        />
                    <path
                        d="m742.9 379.63q0-2.9227 0.99922-5.4208t2.8228-4.3716q3.897-3.9719 10.192-3.9719 5.3208 0 8.5433 3.5222 3.1725 3.3974 3.1725 8.5433 0 1.9735-0.29976 2.6979-2.498 0.69945-10.167 0.69945h-8.7931q0.64949 2.6729 3.0226 4.1718 2.3732 1.4738 6.1702 1.4738 3.9719 0 6.8197-1.4239 0.74941-0.37471 1.1241-0.69946-0.05 1.0492-0.1249 2.1483l-0.27479 3.897q-1.8985 1.299-6.1452 1.7986-1.249 0.14988-2.3981 0.14988-6.3451 0-10.492-3.6971-4.1718-3.7221-4.1718-9.5176zm19.085-3.1476q-1.0242-5.3708-5.8454-5.3708-3.797 0-5.7705 3.2724-0.59954 0.99922-0.92428 2.1983 0.59953 0.025 1.4738 0.05h1.8486q0.92428 0.025 1.7736 0.025h1.4239q1.274 0 2.498-0.025l2.0484-0.0749q0.82435-0.025 1.4738-0.0749z"
                        />
                    <path d="m770.26 366.37h7.0695l6.0952 17.187 5.8954-17.187h6.9196l-10.317 26.105h-5.146z"
                        />
                    <path
                        d="m798.56 379.63q0-2.9227 0.99922-5.4208t2.8228-4.3716q3.897-3.9719 10.192-3.9719 5.3208 0 8.5433 3.5222 3.1725 3.3974 3.1725 8.5433 0 1.9735-0.29977 2.6979-2.498 0.69945-10.167 0.69945h-8.7931q0.64949 2.6729 3.0226 4.1718 2.3732 1.4738 6.1702 1.4738 3.9719 0 6.8197-1.4239 0.74941-0.37471 1.1241-0.69946-0.05 1.0492-0.1249 2.1483l-0.27479 3.897q-1.8985 1.299-6.1452 1.7986-1.249 0.14988-2.3981 0.14988-6.345 0-10.492-3.6971-4.1718-3.7221-4.1718-9.5176zm19.085-3.1476q-1.0242-5.3708-5.8454-5.3708-3.797 0-5.7705 3.2724-0.59953 0.99922-0.92428 2.1983 0.59953 0.025 1.4738 0.05h1.8486q0.92428 0.025 1.7736 0.025h1.4239q1.274 0 2.4981-0.025l2.0484-0.0749q0.82435-0.025 1.4738-0.0749z"
                        />
                    <path d="m835.48 389.68q0 2.1483 0.0999 2.7978h-6.345v-37.221h6.2451z"
                        />
                    <path
                        d="m840.58 379.56q0-2.7478 0.92428-5.2459 0.94926-2.523 2.6979-4.3966 3.822-4.0468 9.9173-4.0468 6.0703 0 9.6425 3.822 3.4223 3.6472 3.4223 9.4676 0 5.8205-3.5223 9.6425-3.7221 4.0468-9.8673 4.0468-6.345 0-9.9422-3.9719-3.2724-3.6222-3.2724-9.3177zm6.0703-0.14988q0 1.5738 0.52459 3.0476t1.4988 2.523q2.0734 2.2732 5.4458 2.2732 3.1226 0 4.9212-2.2233 1.7486-2.1483 1.7486-5.5956 0-3.4973-1.7986-5.6456-1.9235-2.2982-5.2709-2.2982-3.3974 0-5.3208 2.4481-1.7486 2.2482-1.7486 5.4707z"
                        />
                    <path
                        d="m878.85 391.95v7.3443q0 2.1483 0.0999 2.7978h-6.2202v-35.722h6.1202v1.6237q3.0226-2.1233 5.7955-2.1233t4.9212 0.7744q2.1483 0.77439 3.7721 2.3981 3.5722 3.5222 3.5722 10.042 0 4.3216-2.0234 7.719-1.7236 2.8728-4.7713 4.5964-2.6979 1.5238-5.8454 1.5238-3.1475 0-5.4208-0.97424zm0-6.2951q1.8985 1.5988 4.9212 1.5988 4.9461 0 6.395-4.7713 0.49961-1.6237 0.49961-3.4723 0-1.8735-0.34972-2.9727-0.34973-1.1241-0.92428-1.9235-0.54957-0.82436-1.249-1.349-0.69945-0.54957-1.4239-0.87432-1.274-0.57455-2.623-0.57455t-2.7978 0.62452q-1.4489 0.62451-2.4481 1.6737z"
                        />
                    <path
                        d="m900.88 379.63q0-2.9227 0.99922-5.4208t2.8228-4.3716q3.897-3.9719 10.192-3.9719 5.3208 0 8.5433 3.5222 3.1725 3.3974 3.1725 8.5433 0 1.9735-0.29977 2.6979-2.498 0.69945-10.167 0.69945h-8.7931q0.64949 2.6729 3.0226 4.1718 2.3732 1.4738 6.1702 1.4738 3.9719 0 6.8197-1.4239 0.74941-0.37471 1.1241-0.69946-0.05 1.0492-0.1249 2.1483l-0.27479 3.897q-1.8985 1.299-6.1452 1.7986-1.249 0.14988-2.3981 0.14988-6.345 0-10.492-3.6971-4.1718-3.7221-4.1718-9.5176zm19.085-3.1476q-1.0242-5.3708-5.8454-5.3708-3.797 0-5.7705 3.2724-0.59953 0.99922-0.92428 2.1983 0.59953 0.025 1.4738 0.05h1.8486q0.92428 0.025 1.7736 0.025h1.4239q1.274 0 2.4981-0.025l2.0484-0.0749q0.82435-0.025 1.4738-0.0749z"
                        />
                    <path
                        d="m937.9 389.68q0 2.1483 0.0999 2.7978h-6.2202v-26.105h6.1202v1.9235q2.1233-2.4231 4.4465-2.4231 2.3482 0 3.6971 0.57455l-0.44965 6.7198-0.0999 0.0749q-0.87432-1.5488-3.6222-1.5488-1.0492 0-2.1483 0.72444-1.0742 0.69945-1.8236 1.8735z"
                        />
                </g>
                    </g>
                </svg>
                <div className={classes.subtitleImage}>
                    <img alt="carita de carlos padial de pequeño, de cuando era un bebe gordo y rubio" src="./imgs/carita-surreal-50.png"></img>
                </div>
            </React.Fragment>
            
         );
    }
}

export default Subtitle;
