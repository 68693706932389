import * as d3 from "d3";

const generateSea = function() {
    let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const svgHeight = 150;
    const container = '#sea-image';
    const svg = d3.select(container).append('svg')
        .attr('transform', 'translate(0, ' + svgHeight + ')')
    const defs = svg.append('defs');
    const gradient = defs.append('linearGradient').attr('id', 'lastWaveGradient');
    gradient
        .attr('x1', "0")
        .attr('x2', "0")
        .attr('y1', "0")
        .attr('y2', "1")
        .append("stop")
        .attr('offset', "20%")
        .attr('stop-color', "#18CBD633");
    gradient
        .append("stop")
        .attr('offset', "40%")
        .attr('stop-color', "#18CBD6");


    const g = svg.append('g')
        .attr("class",'waves-group');

    const generateWave = function (w, wavelenght, amplitude, yOffset) {
        const path = d3.path();
        path.moveTo(0,yOffset);
        let ampInverter = 1;
        let pointer = 0;
        for (var i = -w * 6; i < w * 12; i = i + wavelenght) {
            let ampli = ampInverter * amplitude + yOffset;
            let cpx = i + wavelenght/2;
            let cpy = ampli;
            path.bezierCurveTo(cpx, cpy, cpx, cpy, i + wavelenght, yOffset);
            ampInverter *= -1;
            pointer = i + wavelenght;
        }
        path.lineTo(pointer, svgHeight + yOffset);
        path.lineTo(0, svgHeight + yOffset);
        path.closePath();
        return path;
    }

    const addWave = function(duration, delay, wavelenght, amplitude, yOffset) {
        const ease = d3.easePoly.exponent(2);
        const path = g.append("path")
            .attr('class', 'wave')
            .attr("d", generateWave(w, wavelenght, amplitude, yOffset));

        const repeatAnimation = function(duration, delay = 0) {
            path.transition()
                .delay(delay)
                .ease(ease)
                .duration(duration)
                .attr("transform", "translate(" + -w/4 + ")")
                .transition()
                .ease(ease)
                .duration(duration)
                .attr("transform", "translate(" + 0 + ")")
                .on("end", function() {
                    repeatAnimation(duration, delay);
                });
        };

        repeatAnimation(duration, delay);
    }

    const animateWaveGroup = function() {
        const ease = d3.easePoly.exponent(2);
        const repeatAnimation = function() {
            g.transition()
                .ease(ease)
                .duration(2000)
                .attr("transform", "translate(0, 25)")
                .transition()
                .duration(2000)
                .attr("transform", "translate(0, 0)")
                .on("end", function(){
                    repeatAnimation();
                })
        }

        repeatAnimation();

        svg.transition()
            .delay(2000)
            .duration(5000)
            .attr("transform", "translate(0, 0)");
    }

    animateWaveGroup();

    //color, opacity, duration, delay, wavelenght, amplitude, yOffset
    addWave(3000, 100 * 2/3, 50, 10, 10);
    addWave(3500, 100 * 3/5, 75, 15, 17);
    addWave(4000, 0       , 100, 20, 25);
    addWave(4000, 0       , 100, 25, 30);
}

export default generateSea;