import React, { Component } from 'react';

import * as d3 from "d3";
import CardBlogPost from './CardBlogPost';
import AutorenewIcon from '@material-ui/icons/Autorenew';

class CardsControl extends Component {

    constructor(props) {
        super(props);
        this.prevButton = React.createRef();
        this.nextButton = React.createRef();
        this.loader = React.createRef();
        this.cardsDeck = React.createRef();
        this.cardPrevious = React.createRef();
        this.cardFront = React.createRef();
        this.cardBack = React.createRef();
        this.cardNext = React.createRef();
        this.state = {
            debug: false,
            postType: 'posts',
            items: [],
            page: 1,
            perPage: 10,
            totalItems: 0,
            totalPages: 0,
            isLoaded: 0,
            trueDirection: true,
        };
    }

    componentDidMount() {
        this.setState({
            isLoaded: 0,
            postType: this.props.postType
        }, () => {
            this.handleFirstApiRequest();
        });
        this.hide(this.prevButton.current);
        this.hide(this.nextButton.current);
        this.show(this.loader.current);
    }

    handleFirstApiRequest() {
        const fields = '';
        const extraUrl = `${this.state.postType}?_embed&page=${this.state.page}&per_page=${this.state.perPage}`;
        const baseUrl = `https://surreal.asturnazari.com/`;
        const url = `${baseUrl}wp-json/wp/v2/${extraUrl}${fields}`;
        // console.log('request: ' + url);
        async function loadData(self) {
            const response = await fetch(url);
            if(!response.ok) {
                // oups! something went wrong
                return;
            }

            const posts = await response.json();

            let totalItems = 0;
            let totalPages = 0;
            for (var pair of response.headers.entries()) {
                if (pair[0] === 'x-wp-total') {
                    totalItems =pair[1];

                }
                if (pair[0] === 'x-wp-totalpages') {
                    totalPages =pair[1];
                }
            }

            self.hide(self.loader.current);
            self.show(self.nextButton.current);

            self.setState({
                items: posts,
                totalItems: totalItems,
                totalPages: totalPages,
                isLoaded: 0
            }, () => {
            });
        }

        loadData(this);
    }

    handleApiRequests(callback) {
        const fields = '';
        const extraUrl = `${this.state.postType}?_embed&page=${this.state.page + 1}&per_page=${this.state.perPage}`;
        // const baseUrl = `http://192.168.0.111/surreal-dev/`;
        const baseUrl = `https://surreal.asturnazari.com/`;
        // const baseUrl = `../surreal/`;
        const url = `${baseUrl}wp-json/wp/v2/${extraUrl}${fields}`;
        // console.log('request: ' + url);

        const items = this.state.items;

        async function loadData(self) {
            let response = await fetch(url);
            if(!response.ok) {
                // oups! something went wrong
                return;
            }

            let posts = await response.json();
            let newItems = items.concat(posts);
            
            let totalItems = 0;
            let totalPages = 0;
            for (var pair of response.headers.entries()) {
                if (pair[0] === 'x-wp-total') {
                    totalItems =pair[1];

                }
                if (pair[0] === 'x-wp-totalpages') {
                    totalPages =pair[1];
                }
            }

            self.hide(self.loader.current);

            self.setState({
                items: newItems,
                totalItems: totalItems,
                totalPages: totalPages,
            }, () => {
                self.handlePageChange(self.state.isLoaded);
            });
        }

        loadData(this);
    }

    flip(previous) {
        this.hide(this.prevButton.current);
        this.hide(this.nextButton.current);
        let rotation = -180 * this.state.isLoaded;
        if (previous) {
            rotation += 180;
        } else {
            rotation -= 180;
        }
        this.cardFront.current.style.transform = "rotate3d(0, 1, 0, " + rotation + "deg)";
        this.cardBack.current.style.transform = "rotate3d(0, 1, 0, " + rotation + "deg)";
    }

    hide(el) {
        d3.select(el).style('display', 'none')
    }

    show(el) {
        d3.select(el).style('display', 'inherit')
    }

    text(el, text) {
        d3.select(el).html(text);
    }

    updatePage() {
        const item = this.state.isLoaded;
        const perPage = this.state.perPage;
        let page = parseInt((item) / perPage) + 1;
        if (this.state.page !== page) {
            this.setState({
                page: page,
            })
        }
    }

    handlePageChange(index) {
        this.setState({
            isLoaded: index,
        }, () => {
            if (this.state.isLoaded === 0) {
                this.hide(this.prevButton.current);
            } else  {
                this.show(this.prevButton.current);
            }
            
            if (this.state.isLoaded === this.state.items.length -1) {
                this.hide(this.nextButton.current);
                // this.show(this.loader.current);
            } else {
                // this.hide(this.loader.current);
                this.show(this.nextButton.current);
            }

            if (this.state.isLoaded + 1 === Math.max(this.state.totalItems, this.state.items.length)) {
                // this.hide(this.loader.current);
                this.hide(this.nextButton.current);
            }

            if (this.state.isLoaded > this.state.items.length - 5) {
                if (this.state.page < this.state.totalPages) {
                    this.handleApiRequests();               
                }
            }
            this.updatePage();
        })
    }

    previous = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.isLoaded > 0) {
            this.flip(true);
            setTimeout(() => {
                this.hide(this.cardNext.current);
                this.show(this.cardPrevious.current);
                this.handlePageChange(this.state.isLoaded - 1);
                this.show(this.prevButton.current);
                this.show(this.nextButton.current);
            }, 500);
        }
    }

    next = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.flip();
        setTimeout(() => {
            this.hide(this.cardPrevious.current);
            this.show(this.cardNext.current);
            this.handlePageChange(this.state.isLoaded + 1);
            this.show(this.prevButton.current);
            this.show(this.nextButton.current);
        }, 500);
    }

    getContent = function(index) {
        // const classes = this.props.classes;
        let content = null;
        const data = this.state.items[index];
        if (data)
        content = <CardBlogPost
                    postType={this.state.postType} 
                    classes={this.props.classes} 
                    data={data}
                />
        return content;
    }

    render() {
        const classes = this.props.classes;
        // const data = this.state.items[this.state.isLoaded];

        const previous = this.previous;
        const next = this.next;
        
        let isEvenPage = this.state.isLoaded % 2 === 0;
        let contentFront = null;
        let contentNext = null;
        let contentPrev = null;
        if (this.state.items[this.state.isLoaded]) {
            if (this.state.isLoaded === 0) {
                contentFront = this.getContent(this.state.isLoaded);
                contentNext = this.getContent(this.state.isLoaded + 1);
            } else {
                if (isEvenPage) {
                    contentFront = this.getContent(this.state.isLoaded)
                    contentNext = this.getContent(this.state.isLoaded + 1);
                    contentPrev = this.getContent(this.state.isLoaded - 1);
                } else {
                    contentFront = this.getContent(this.state.isLoaded - 1);
                    contentNext = this.getContent(this.state.isLoaded);
                    contentPrev = this.getContent(this.state.isLoaded);
                }
            }
        }

        let debugInfo = null;
        if (this.state.debug) {
            debugInfo = `${this.state.isLoaded + 1} - ${this.state.totalItems} - (${this.state.items.length})`;
        }

        return (
            <div className={`${classes.cardsGrid}`}>
                <div id="prev" ref={this.prevButton} >
                    <div onClick={previous}></div>
                </div>
                <div id="cardsDeck" ref={this.cardsDeck} className={classes.overlapContainer}>
                    <div ref={this.cardBack} className={`${classes.overlapped} ${classes.overlapContainer} flip-card flip-card-back`}>
                        <div id="card3" ref={this.cardPrevious} className={`${classes.overlapped} flip-card flip-card-back-p`}>
                            {debugInfo}
                            {contentPrev}
                        </div>
                        <div id="card2" ref={this.cardNext} className={`${classes.overlapped} flip-card flip-card-back-n`}>
                            {debugInfo}
                            {contentNext}
                        </div>
                    </div>
                    <div id="card1" ref={this.cardFront} className={`${classes.overlapped} flip-card flip-card-front`}>
                        {debugInfo}
                        {contentFront}
                    </div>
                </div>
                <div id="next" ref={this.nextButton}>
                    <div onClick={next}></div>
                </div>
                <div id="loader" ref={this.loader}>
                    <AutorenewIcon className={classes.loaderIcon}/>
                </div>
            </div>
         );
    }
}

export default CardsControl;
