import React, { Component } from 'react';
import CardsControl from './CardsControl.js';

class Section3 extends Component {

    render() { 
        const classes = this.props.classes;
        const state = this.props.state;
        let cardPosition = null;
        let cardsContainerRatio = null;
        let ratio = parseFloat(state.ratio.split(':')[0])
        if (ratio < 0.8) {
            cardPosition = classes.cardsContainerC;
            cardsContainerRatio = classes.cardsContainerV;
        } else if (ratio < 1.2) {
            cardPosition = classes.cardsContainerB;
            cardsContainerRatio = classes.cardsContainerV;
        } else {
            cardPosition = classes.cardsContainerA;
            cardsContainerRatio = classes.cardsContainerH;
        }

        return ( 
            <div className={classes.section3}>
                <div className={cardsContainerRatio}>
                    <div className={cardPosition}>
                        <CardsControl classes={classes} postType="posts"/>
                    </div>
                    <div className={cardPosition}>
                        <CardsControl classes={classes} postType="jetpack-portfolio"/>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default Section3;