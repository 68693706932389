import React, { Component } from 'react';
import AnimatedEye from './AnimatedEye';
import Signature from './Signature';
import Subtitle from './Subtitle';

class MainTitle extends Component {
    render() {
        const classes = this.props.classes;
        
        return (
            <div id='mainTitle' className={classes.mainTitle}>
                    <AnimatedEye classes={classes}/>
                    <div className={classes.titleAndSubtitle}>
                        <Signature classes={classes}/>
                        <Subtitle classes={classes}/>
                    </div>
            </div>
         );
    }
}

export default MainTitle;