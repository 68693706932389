import React, { Component } from 'react';

class Debug extends Component {
    render() {
        const classes = this.props.classes;
        
        return (
            <div className={classes.debug} id="debug">
                <div className="first-line"></div>
                <div className="second-line"></div>
                <div className="third-line"></div>
                <div className="fourth-line"></div>
            </div>
        );
    }
}

export default Debug;
