let node_data = [
    {x: 486.3212256355833, y: 271.6166640754236, r: 14.078583340441219},
    {x: 49.99334320050996, y: 216.713326271637, r: 9.933145249043951},
    {x: 319.23697575333296, y: 222.59765334998852, r: 20.73992306125061},
    {x: 489.04983111993664, y: 214.68066305834049, r: 15.9136133449681125},
    {x: 539.6367706753124, y: 420.5565132318666, r: 17.565863830729214},
    {x: 275.486491113041, y: 199.611114220203, r: 16.18125367313447},
];

let edge_data = [
    {source: 0, target: 1},
    {source: 1, target: 2},
    {source: 2, target: 0},
    {source: 3, target: 4},
    {source: 4, target: 5},
    {source: 5, target: 3}];

export { node_data, edge_data };
