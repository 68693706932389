import React, { Component } from 'react';

import {drawForce} from "../modules/drawForce.js";
import Bubbles from './Bubbles';

class Section2 extends Component {
    constructor(props) {
        super(props);
        this.element = React.createRef();
        this.sectionsTemplate = React.createRef();
        this.state = {
            bubblesData: null,
        };
    }

    setBubblesData = (data, callback) => {
        this.setState({
            bubblesData: data,
        }, callback);
    }

    componentDidMount() {
        const setBubblesData = this.setBubblesData;
        const catUrl = 'https://surreal.asturnazari.com//wp-json/wp/v2/jetpack-portfolio-type?per_page=100&_fields=name,id';
        const portUrl = 'https://surreal.asturnazari.com//wp-json/wp/v2/jetpack-portfolio?per_page=100&_fields=slug,title,id,jetpack-portfolio-type,link,date';

        // let data = 
        Promise.all([
            fetch(catUrl),
            fetch(portUrl)
        ]).then(function(responses) {
            return Promise.all(responses.map(function (response) {
                return response.json();
            }));
        }).then(function (data) {
            let cats = data[0];
            let items = data[1];

            const getCatName = function (id) {
                for (let j = 0; j < cats.length; j++) {
                    const element = cats[j];
                    if (element.id === id) {
                        return element.name
                    }
                }
            }

            let linksCollection = {};
            let nodesCollection = {};
            let categoriesPerPost = [];
            for (let index = 0; index < items.length; index++) {
                categoriesPerPost = items[index]['jetpack-portfolio-type'];
                for (let i = 0; i < categoriesPerPost.length; i++) {
                    let sourceName = getCatName(categoriesPerPost[i]);
                    if (nodesCollection[sourceName]) {
                        nodesCollection[sourceName].value += 1;
                    } else {
                        nodesCollection[sourceName] = {
                            name: sourceName,
                            value: 1,
                            group: 1
                        }
                    }
                    if (categoriesPerPost.length > 1) {
                        for (let j = 0; j < categoriesPerPost.length; j++) {
                            if (j !== i) {
                                let targetName = getCatName(categoriesPerPost[j]);
                                let source = Math.min(categoriesPerPost[i], categoriesPerPost[j]);
                                let target = Math.max(categoriesPerPost[i], categoriesPerPost[j]);
                                let link = 'L-' + source + '-' + target;
                                if (linksCollection[link]) {
                                    linksCollection[link].value += 1;
                                } else {
                                    linksCollection[link] = {
                                        value: 1,
                                        sourceName: sourceName, targetName: targetName
                                        };
                                }
                            }
                        }
                    }
                }
            }
            // console.log('nodes', nodesCollection);
            // console.log('links', linksCollection);

            let nodes = [];
            for (let i = 0; i < Object.keys(nodesCollection).length; i++) {
                const element = nodesCollection[Object.keys(nodesCollection)[i]];
                nodes.push({
                    name: element.name,
                    value: element.value,
                    group: element.group
                })   
            }

            const getNode = function(name) {
                for (let i = 0; i < nodes.length; i++) {
                    const element = nodes[i];
                    if (element.name === name) {
                        return i;
                    }
                    
                }
            }

            let links = [];
            for (let i = 0; i < Object.keys(linksCollection).length; i++) {
                const element = linksCollection[Object.keys(linksCollection)[i]];
                links.push({
                    source: getNode(element.sourceName),
                    target: getNode(element.targetName),
                    value: element.value
                })
            }

            const finalData = {
                nodes: nodes,
                links: links
            };
            setBubblesData(finalData, function() {
                drawForce('#forces', this.props.w, this.props.h, this.state.bubblesData);
            });
            return finalData;
        });
    }

    componentDidUpdate() {
        // if (this.state.bubblesData !== null) {
        //     drawForce('#forces', this.props.w, this.props.h, this.state.bubblesData);
        // }
    }

    render() { 
        const classes = this.props.classes;
        const props = this.props;
        return ( 
            <div className={classes.section2}>
                <div className="main-bubbles-container">
                    <div className={classes.overlapContainer}>
                        <div id="forces" className={classes.overlapped}></div>
                        <div id="bubbles" className={classes.overlapped}>
                            <Bubbles w={props.w} h={props.h}/>
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default Section2;